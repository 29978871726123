.dynamic-access-card-icon {
  .main {
    justify-content: center;
    margin-top: 30px;

    .title,
    .subtitle {
      color: $andes-gray-900;
      margin: 0 0 $andes-spacing-8 0;
    }

    svg {
      margin-bottom: $andes-spacing-8;
    }

    .description {
      -webkit-line-clamp: 3;
      max-height: 4.6em;
    }
  }
}

.dynamic-access-card-ilustrator {
  .header {
    display: flex;
    max-width: 183px;

    &__title {
      line-height: 15px;
      width: 100%;
      margin: 0;
      font-weight: $font-weight-semibold;
      padding: $andes-spacing-8 $andes-spacing-8 $andes-spacing-4
        $andes-spacing-8;
      font-size: $font-size-12;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none;

      &--color-white {
        color: $andes-white;
      }

      &--bg-color-green_primary {
        border-radius: var(
            --dynamic-access-card-border-radius,
            $border-radius-6
          )
          var(--dynamic-access-card-border-radius, $border-radius-6) 0 0;
        background-color: $andes-green-500;
      }
    }

    + .main {
      margin-top: 0;
    }
  }

  

  .main {
    justify-content: flex-start;
    margin-top: $andes-spacing-12;

    .title,
    .subtitle {
      color: $andes-blue-500;
      margin: 0;

      &--color-green_primary {
        color: $andes-green-500;
      }
    }
  }
}

.dynamic-access-card-icon,
.dynamic-access-card-ilustrator {
  .main {
    img {
      margin: auto;
      margin-bottom: $andes-spacing-8;
      object-fit: fill;
    }
  }
  &:has(.header) .main img{
    max-height: 65px;
    margin-bottom: $andes-spacing-12;
  }
}
